// App.js
import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import './App.css'
import circle from './App.css'
import Select from 'react-select';
import {useDropzone} from "react-dropzone";

const App = () => {
    const [inputTextTurn, setInputTextTurn] = useState('');
    const [inputText, setInputText] = useState('');
    const [numbers, setNumbers] = useState([]);
    const [popupWindow, setPopupWindow] = useState(null);
    const [generatingNumber, setGeneratingNumber] = useState(false);
    const [randomNumber, setRandomNumber] = useState(undefined);
    const [rerenderKey, setRerenderKey] = useState(0);
    const bingoLetters = ['B', 'I', 'N', 'G', 'O'];
    const [selectedBingoTurn, setSelectedBingoTurn] = useState(null);
    const [video, setVideo] = useState(null);
    const videoRef = useRef(null);
    const [playing, setPlaying] = useState(false);



    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setVideo(URL.createObjectURL(file));
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'video/*' });

    const [bingoTurns, setBingoTurns] = useState([
        {value: 'ABELHA', label: 'ABELHA'},
        {value: 'BALEIA', label: 'BALEIA'},
        {value: 'BORBOLETA', label: 'BORBOLETA'},
        {value: 'CABRITO', label: 'CABRITO'},
        {value: 'CACHORRO', label: 'CACHORRO'},
        {value: 'CAMELO', label: 'CAMELO'},
        {value: 'CANGURU', label: 'CANGURU'},
        {value: 'CARANGUEIJO', label: 'CARANGUEIJO'},
        {value: 'CAVALO', label: 'CAVALO'},
        {value: 'COBRA', label: 'COBRA'},
        {value: 'COELHO', label: 'COELHO'},
        {value: 'ESPECIAL', label: 'ESPECIAL'},
        {value: 'ELEFANTE', label: 'ELEFANTE'},
        {value: 'GALINHA', label: 'GALINHA'},
        {value: 'GATO', label: 'GATO'},
        {value: 'GAVIÃO', label: 'GAVIÃO'},
        {value: 'GIRAFA', label: 'GIRAFA'},
        {value: 'RINOCERONTE', label: 'RINOCERONTE'},
        {value: 'JACARÉ', label: 'JACARÉ'},
        {value: 'LEÃO', label: 'LEÃO'},
        {value: 'MACACO', label: 'MACACO'},
        {value: 'PAPAGAIO', label: 'PAPAGAIO'},
        {value: 'PATO', label: 'PATO'},
        {value: 'ESPECIAL', label: 'ESPECIAL'},
        {value: 'PAVÃO', label: 'PAVÃO'},
        {value: 'PORCO', label: 'PORCO'},
        {value: 'TARTARUGA', label: 'TARTARUGA'},
        {value: 'TATU', label: 'TATU'},
        {value: 'TUBARÃO', label: 'TUBARÃO'},
        {value: 'URSO', label: 'URSO'},
        {value: 'VACA', label: 'VACA'},
        {value: 'ZEBRA', label: 'ZEBRA'},
        {value: '0800', label: '0800'},
    ]);


    const [smallFontSize, setSmallFontSize] = useState(150);
    const [bigFontSize, setBigFontSize] = useState(600);

    const handleIncreaseFont = () => {
        setBigFontSize((prevFontSize) => prevFontSize + (prevFontSize * 0.02));
        setSmallFontSize((prevFontSize) => prevFontSize + (prevFontSize * 0.02));
    };

    const handleDecreaseFont = () => {
        setBigFontSize((prevFontSize) => Math.max(prevFontSize - (prevFontSize * 0.02), 10));
        setSmallFontSize((prevFontSize) => Math.max(prevFontSize - (prevFontSize * 0.02), 10));
    };

    const openPopup = () => {

        if (!inputTextTurn || inputTextTurn.trim() === '') {
            alert('Selecione ou digite um nome para a Rodada.')
            return;
        }
        if (!popupWindow || popupWindow.closed) {
            const newPopupWindow = window.open('', '_blank', 'width=1366,height=768');
            setPopupWindow(newPopupWindow);
        }
        setNumbers([]);
        setInputText('');
        setPlaying(true);
    };

    const updatePopupContent = (generatingNumber, randomNumber) => {
        if (popupWindow) {

            const lastItems = [];
            for (let i = numbers.length - (generatingNumber ? 1 : 2); i >= Math.max(numbers.length - (generatingNumber ? 4 : 5), 0); i--) {
                lastItems.push(numbers[i]);
            }

            const content = (

                <>

                    {playing && video ? (
                            <video
                                ref={videoRef}
                                autoPlay
                                loop={true}
                                controls
                                style={{width: '100%', height: 'auto', marginTop: '20px'}}
                            >
                                <source src={video} type="video/mp4"/>
                                Seu navegador não suporta o elemento de vídeo.
                            </video>
                        ) :

                        <div style={{position: 'relative', fontFamily: 'Arial'}}>
                            <style>
                                {`@import url('https://fonts.googleapis.com/css2?family=Sua+Fonte:ital,wght@0,400;0,700;1,400&display=swap');`}
                            </style>
                            <div style={{
                                fontSize: `100px`,
                                textAlign: "center"
                            }}>Rodada: {inputTextTurn.toUpperCase()}</div>

                            <div style={{display: "flex", height: '100%', textAlign: "center"}}>
                                <div style={{alignItems: "center", backgroundColor: '#f0f0f0'}}>
                                    {lastItems.map((item, index) => {
                                        return (
                                            <div style={{display: "flex"}} key={index}>
                                                <div style={{
                                                    minWidth: '100px',
                                                    fontSize: `${smallFontSize}px`,
                                                    color: '#a0a0a0'
                                                }}>
                                                    {selectLetter(item)}
                                                </div>
                                                <div style={{minWidth: '140px', fontSize: `${smallFontSize}px`}}>
                                                    {formatNumber(item)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div style={{alignItems: "center", display: 'flex'}}>

                                    {generatingNumber && randomNumber ?
                                        <>
                                            <div style={{
                                                minWidth: '250px',
                                                fontSize: `${bigFontSize}px`,
                                                color: '#a0a0a0'
                                            }}>
                                                {selectLetter(randomNumber)}
                                            </div>
                                            <div style={{minWidth: '750px', fontSize: `${bigFontSize}px`}}>
                                                {formatNumber(randomNumber)}
                                            </div>
                                        </>
                                        :
                                        numbers.length > 0 &&
                                        <>
                                            <div style={{fontSize: `${bigFontSize}px`, color: '#a0a0a0'}}>
                                                {selectLetter(numbers[numbers.length - 1])}
                                            </div>
                                            <div style={{fontSize: `${bigFontSize}px`}}>
                                                {formatNumber(numbers[numbers.length - 1])}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            <div style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px'
                            }}>
                                <button onClick={handleDecreaseFont}>-A</button>
                                <button onClick={handleIncreaseFont}>+A</button>
                            </div>
                        </div>
                    }

                </>

            );

            ReactDOM.render(content, popupWindow.document.body);
        }
    };

    function formatNumber(number) {
        return number < 10 ? '0' + number : number.toString();
    }

    useEffect(() => {
        updatePopupContent();
        setRerenderKey((prevKey) => prevKey + 1);
    }, [numbers]);

    useEffect(() => {
        updatePopupContent();
    }, [popupWindow]);

    useEffect(() => {
        updatePopupContent();
    }, [bigFontSize]);

    useEffect(() => {
        updatePopupContent();
    }, [smallFontSize]);


    useEffect(() => {
        updatePopupContent();
    }, [inputTextTurn]);


    useEffect(() => {
        updatePopupContent(generatingNumber, randomNumber);
    }, [generatingNumber, randomNumber]);

    function addNumber(number) {
        if (numbers.length === 75) {
            alert('Todos os números foram sorteados!');
            return;
        }
        if (!popupWindow || popupWindow.closed) {
            alert('Inicie uma nova rodada.');
            return;
        }
        number = Number(number);
        if (number < 1 || number > 75) {
            alert('Número fora da faixa de 1 a 75.');
            return;
        }
        if (numbers.find((n) => n === number)) {
            alert('Número já foi sorteado.');
            return;
        }
        setPlaying(false);
        setNumbers([...numbers, number]);
        setInputText('');
    }

    function selectLetter(number) {
        if (number >= 1 && number <= 75) {
            let index = Math.floor((number - 1) / 15);
            return bingoLetters[index];
        } else {
            return "Número fora do intervalo";
        }
    }

    function removeNumber(item) {
        setNumbers(numbers.filter((n) => n !== item));

    }


    function generateRandomNumberExclusive(start, end, excludeNumbers) {
        let randomNumber = 0;
        do {
            randomNumber = Math.floor(Math.random() * end) + start
        } while (excludeNumbers.find((n) => n === randomNumber))
        return randomNumber;
    }

    function generateRandomNumber(start, end) {
        return Math.floor(Math.random() * end) + start;
    }

    async function handleRandomButtonClick() {
        if (!inputTextTurn) {
            alert('Inicie uma nova rodada.');
            return;
        }
        if (numbers.length === 75) {
            alert('Todos os números foram sorteados!');
            return;
        }
        setPlaying(false);
        await setGeneratingNumber(true);
        let i = 0;
        while (i < 100) {
            await setRandomNumber(generateRandomNumber(1, 75));
            await new Promise(resolve => setTimeout(resolve, generateRandomNumber(10, 20)));
            i++;
        }
        addNumber(generateRandomNumberExclusive(1, 75, numbers));
        await setGeneratingNumber(false);
    }

    const [selection, setSelection] = useState('manual');

    const handleSelectionChange = (event) => {
        setSelection(event.target.value);
    };


    const styles = {
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            border: '1px solid #ddd',
        },
        cell: {
            border: '1px solid #ddd',
            padding: '8px',
            textAlign: 'left',
        },
    };

    const handleBingoTurnChange = (selectedOption) => {
        setSelectedBingoTurn(selectedOption);
        setInputTextTurn(selectedOption.value);
    };

    const dropzoneStyles = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginTop: '20px',
    };

    function handleBingoTurnInputChange(input) {
        if(input !== '') {
            setInputTextTurn(input);
        }
    }

    async function handleBingoTurnBlur() {
        if (!bingoTurns.find((b) => b.value === inputTextTurn)) {
            const bingoTurn = {label: inputTextTurn, value: inputTextTurn};
            bingoTurns.push(bingoTurn);
            setBingoTurns(bingoTurns);
            setSelectedBingoTurn(bingoTurn);
            setInputTextTurn(bingoTurn.value);
        }
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
            <div>
                <div>
                    <div>
                        <div {...getRootProps()} style={dropzoneStyles}>
                            <input {...getInputProps()} />
                            <p>Adicione um vídeo aqui para ser mostrado no intervalo das rodadas.</p>
                        </div>
                    </div>
                    <div>

                        <label>
                            <input
                                type="radio"
                                value="manual"
                                checked={selection === 'manual'}
                                onChange={handleSelectionChange}
                            />
                            Sorteio Manual
                        </label>

                        <label>
                            <input
                                type="radio"
                                value="random"
                                checked={selection === 'random'}
                                onChange={handleSelectionChange}
                            />
                            Sorteio Automático
                        </label>
                    </div>

                    <div style={{display: "flex", marginTop: '20px'}}>
                        <div style={{display: "flex", alignItems:"center"}}>
                            <div>
                                Nome da Rodada:
                            </div>
                            <div style={{width: '470px', marginLeft: '5px'}}>
                                <Select
                                    placeholder={"Selecione a rodada"}
                                    value={selectedBingoTurn}
                                    onChange={handleBingoTurnChange}
                                    onInputChange={(e) => handleBingoTurnInputChange(e)}
                                    onBlur={handleBingoTurnBlur}
                                    options={bingoTurns}
                                />
                            </div>
                            <div style={{marginLeft: '5px'}}>
                                <button disabled={generatingNumber} onClick={openPopup}>Nova Rodada</button>
                            </div>
                        </div>

                    </div>
                    <div style={{marginTop: '20px'}}>

                        {selection === 'random' &&
                            <button style={{marginTop: '10px'}} disabled={generatingNumber}
                                    onClick={handleRandomButtonClick}>Sortear Número</button>
                        }

                    </div>


                </div>
                <div>
                    <div style={{marginTop: '20px', display: "flex"}}>
                        <div style={{alignItems: "center", textAlign: "center"}}>

                            <table key={rerenderKey} style={styles.table}>

                                <tbody>
                                {Array.from({length: 5}, (_, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td key={rowIndex + bingoLetters[rowIndex]} style={styles.cell}>
                                            <div style={{
                                                fontWeight: "bold",
                                                fontSize: '20px',
                                                textAlign: "center"
                                            }}>{bingoLetters[rowIndex]}</div>
                                        </td>
                                        {Array.from({length: 15}, (_, colIndex) => {
                                            const number = rowIndex * 15 + colIndex + 1;
                                            const isDrawn = numbers.includes(number);
                                            return (
                                                <td key={colIndex}
                                                    style={styles.cell}
                                                >
                                                    <div
                                                        onClick={() => {

                                                            if (isDrawn) {
                                                                removeNumber(number);
                                                            } else {
                                                                addNumber(number);
                                                            }
                                                        }}
                                                        className={circle} style={{
                                                        backgroundColor: isDrawn ? (number === numbers[numbers.length - 1] ? "red" : "yellow") : "while",
                                                        color: isDrawn ? "black" : '#a0a0a0',
                                                        fontSize: '20px',
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                        width: '30px',
                                                        height: '30px',
                                                        border: '1px solid',
                                                        borderRadius: '50%'
                                                    }}>{formatNumber(number)}</div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;